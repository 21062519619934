:root {
    --ls-main: #43a6d0;
    --ls-main-accent: #99cfe6;
    --ls-second: #7d2c3b;
    --ls-second-accent: #62222e;
    --ls-contrast: #f8d742;
    --ls-contrast-dark: #c9ad2b;
    --ls-contrast-accent: #f6d441;
    --ls-social-link-color: #ffffff;
    --ls-social-link-color-hover: #ffffff;
}

.ls-container {
    background-color: var(--ls-main) !important;
}

.ls-col {
    max-width: 380px !important;
}

.ls-card {
    width: 100% !important;
    background-color: var(--ls-second) !important;
    color: var(--ls-contrast) !important;
}

.ls-card input {
    background-color: var(--ls-second-accent) !important;
}

.ls-card button {
    background-color: var(--ls-contrast-dark) !important;
    border-color: var(--ls-contrast-accent) !important;
    color: var(--ls-second) !important;
}

.ls-card button:hover {
    background-color: var(--ls-contrast) !important;
    border-color: var(--ls-contrast-accent) !important;
    color: var(--ls-second-accent) !important;
}

.ls-credits {
    text-align: center;
    margin-top: -40px;
}

.ls-credits a {
    color: var(--ls-second);
}

.ls-credits a:hover {
    color: var(--ls-second-accent);
}