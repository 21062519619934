.navigation-section {
    --gb-navbar-padding-x: 0;
    --gb-navbar-padding-y: 0.5rem;
    --gb-navbar-color: rgba(0, 0, 0, 0.55);
    --gb-navbar-hover-color: rgba(0, 0, 0, 0.7);
    --gb-navbar-disabled-color: rgba(0, 0, 0, 0.3);
    --gb-navbar-active-color: rgba(0, 0, 0, 0.9);
    --gb-navbar-brand-padding-y: 0.3125rem;
    --gb-navbar-brand-margin-end: 1rem;
    --gb-navbar-brand-font-size: 1.25rem;
    --gb-navbar-brand-color: rgba(0, 0, 0, 0.9);
    --gb-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
    --gb-navbar-nav-link-padding-x: 0.5rem;
    --gb-navbar-toggler-padding-y: 0.25rem;
    --gb-navbar-toggler-padding-x: 0.75rem;
    --gb-navbar-toggler-font-size: 1.25rem;
    --gb-navbar-toggler-icon-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 0, 0, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e);
    --gb-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
    --gb-navbar-toggler-border-radius: 0.375rem;
    --gb-navbar-toggler-focus-width: 0.25rem;
    --gb-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
}

.navigation-section-link {
    --gb-nav-link-padding-x: 0;
    --gb-nav-link-padding-y: 0.5rem;
    --gb-nav-link-font-weight: ;
    --gb-nav-link-color: var(--gb-navbar-color);
    --gb-nav-link-hover-color: var(--gb-navbar-hover-color);
    --gb-nav-link-disabled-color: var(--gb-navbar-disabled-color);
}

.navigation-section.navigation-section-link {
    display: block;
    font-size: var(--gb-nav-link-font-size);
    font-weight: var(--gb-nav-link-font-weight);
    color: var(--gb-nav-link-color);
    text-decoration: none;
    transition: color .15s ease-in-out,var(--gb-nav-link-color) .15s ease-in-out,var(--gb-nav-link-hover-color) .15s ease-in-out;
}

.navigation-section.navigation-section-link:hover {
    color: var(--gb-nav-link-hover-color);
}

.navigation-section.navigation-section-link.active {
    color: var(--gb-navbar-active-color);
}