.handle-navigate {
    cursor: pointer;
}

.module-frame {
    border: var(--bs-fit-border-width) var(--bs-border-style) var(--bs-border-color)!important;
	border-radius: 0.25rem;
    margin: 0.25rem!important;
    padding-top: 0.5rem!important;
    padding-bottom: 0.5rem!important;
    padding-left: 0.5rem!important;
    padding-right: 0.5rem!important;
}

.bs-fit-fill {
    object-fit: fill !important;
}

.bs-fit-contain {
    object-fit: contain !important;
}

.bs-fit-cover {
    object-fit: cover !important;
}

.bs-fit-scale-down {
    object-fit: scale-down !important;
}

.bs-fit-none {
    object-fit: none !important;
}