.handle-navigate {
    cursor: pointer;
}

.module-frame {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
	border-radius: 0.25rem;
    margin: 0.25rem!important;
    padding-top: 0.5rem!important;
    padding-bottom: 0.5rem!important;
    padding-left: 0.5rem!important;
    padding-right: 0.5rem!important;
}